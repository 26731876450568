// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	'aws_project_region': 'us-east-2',
	'aws_cognito_identity_pool_id': 'us-east-2:90f58578-edff-43f1-8e93-75ec2a7521b2',
	'aws_cognito_region': 'us-east-2',
	'aws_user_pools_id': 'us-east-2_k7jn7YIM1',
	'aws_user_pools_web_client_id': 'vui08qd6e5t6ej78qv5q017kh',

	'oauth': {
		'domain': 'invt-sample.auth.us-east-2.amazoncognito.com',
		'scope': [
			'phone',
			'email',
			'openid',
			'profile',
			'aws.cognito.signin.user.admin'
		],
		'redirectSignIn': 'https://dev.inventra.net/auth/signin/',
		'redirectSignOut': 'https://dev.inventra.net/auth/signin/',
		'responseType': 'code'
	},
	'federationTarget': 'COGNITO_USER_POOLS'
};


export default awsmobile;
