export const environment = {
  production: false,
  env: 'dev',
  inventra: {
    access: {
      api_url: 'https://65e9iueyr9.execute-api.us-east-2.amazonaws.com/dev/'
    },
    profile: {
      api_url: 'https://bxs214os7e.execute-api.us-east-2.amazonaws.com/dev/'
    },
    project: {
      api_url: 'https://4e16kydppj.execute-api.us-east-2.amazonaws.com/dev/'
    },
    company: {
      api_url: 'https://pmk1v01vze.execute-api.us-east-2.amazonaws.com/dev/'
    },
    gin: {
      api_url: 'https://pbnxb6utvk.execute-api.us-east-2.amazonaws.com/dev/'
    },
    grn: {
      api_url: 'https://1b3yko59a2.execute-api.us-east-2.amazonaws.com/dev/'
    },
    numbers: {
      api_url: 'https://zgyz1v7sk6.execute-api.us-east-2.amazonaws.com/dev/'
    },
    product_type: {
      api_url: 'https://sm535ebfjf.execute-api.us-east-2.amazonaws.com/dev/'
    },
    product: {
      api_url: 'https://r928gaif24.execute-api.us-east-2.amazonaws.com/dev/'
    },
    project_stage: {
      api_url: 'https://4e16kydppj.execute-api.us-east-2.amazonaws.com/dev/'
    },
    project_status: {
      api_url: 'https://4e16kydppj.execute-api.us-east-2.amazonaws.com/dev/'
    },
    stock_report: {
      api_url: 'https://docdqvu9qg.execute-api.us-east-2.amazonaws.com/dev/'
    },
    roles: {
      api_url: 'https://q25971fsla.execute-api.us-east-2.amazonaws.com/dev/'
    },
    attributes: {
      api_url: 'https://nhyo7a4xyd.execute-api.us-east-2.amazonaws.com/dev/'
    },
    image: {
      api_url: 'https://ye8xqudg98.execute-api.us-east-2.amazonaws.com/dev/'
    },
    store: {
      api_url: 'https://qnv7yzd9hj.execute-api.us-east-2.amazonaws.com/dev/'
    },
    location: {
      api_url: 'https://1h3nzfwh1i.execute-api.us-east-2.amazonaws.com/dev/'
    },
    suplier: {
      api_url: 'https://vrnjy5r1v0.execute-api.us-east-2.amazonaws.com/dev/'
    },
    uom: {
      api_url: 'https://z9v889tc14.execute-api.us-east-2.amazonaws.com/dev/'
    }

  },
  // Dashbord Widgets
  dashboard: {
    revenue: {
      active: true
    },
    sales: {
      active: true
    },
    users: {
      active: true
    },
    month_satats: {
      active: true
    },
    feeds: {
      active: true
    },
    market_records: {
      active: true
    },
  },

  // Side Menu Config
  sideMenu: {
    dashboard: {
      active: true
    },
    products: {
      active: true
    },
    inventory: {
      active: true,
      gr: {
        active: true
      },
      gi: {
        active: true
      },
      cr: {
        active: true
      }
    },
    marketplace: {
      active: true,
      marketplaces: {
        active: true
      },
      orders: {
        active: true
      },
      customers: {
        active: true
      }
    },
    projects: {
      active: true
    },
    reports: {
      active: true,
      sr: {
        active: true
      },
      asr: {
        active: true
      },
      slr: {
        active: true
      },
      tsr: {
        active: true
      },
      pr: {
        active: true
      }
    },
    user: {
      active: true,
      users: {
        active: true
      },
      roles: {
        active: true
      }
    },
    system: {
      active: true,
      company: {
        active: true
      },
      stores: {
        active: true
      },
      locations: {
        active: true
      },
      suppliers: {
        active: true
      },
      uom: {
        active: true
      },
      attributes: {
        active: true
      },
      project: {
        active: true
      },
      customerReturn: {
        active: true
      }
    },
  }

};
